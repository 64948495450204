import * as React from 'react'
import { useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image'
import { ComMain, QualityLinkBox } from '@components/index'
import { FeedInFunc } from '@func/index'

const QualityPage = () => {

  useEffect(() => {
    FeedInFunc();
  }, []);

  return (
    <main className='mainQuality'>
      {/* CONTENTS */}

      <ComMain
        ttl="QUALITY"
        subttl="設備仕様"
        caption="KITCHEN"
      >
        <StaticImage
          src="../assets/images/quality/main.jpg"
          alt=""
          className="_pc"
        />
        <StaticImage
          src="../assets/images/quality/main_sp.jpg"
          alt=""
          className="_sp"
        />
      </ComMain>

      <section className='sec-kit'>

        <QualityLinkBox
          act='kit'
        />

        <div className="kit-wrap" id='kit'>
          {/* <div className="c-content">

            <p className='com-ttl'>水回りこそ美しさとセンス、居心地の良さにこだわって。</p>
            <div className="img-box">
              <div className="img">
                <StaticImage src="../assets/images/quality/kit_01.jpg" alt="" />
              </div>
              <p className="c-cap">KITCHEN</p>
            </div>

          </div> */}

          <div className="com-flex">
            <p className='com-ttl'>水回りこそ美しさとセンス、<br className='_sp' />居心地の良さにこだわって。</p>
            <div className="img-box main-img">
              <div className="img">
                <StaticImage src="../assets/images/quality/kit_01.jpg" alt="" />
              </div>
              <p className="c-cap cap">KITCHEN</p>
            </div>

            <div className="box box-1">
              <div className="img-box">
                <div className="img">
                  <StaticImage src="../assets/images/quality/kit_02.jpg" alt="" />
                </div>
                <p className="c-cap"></p>
              </div>
              <div className="txt-box">
                <p className='ttl'>浄水機能付き混合水栓</p>
                <p className='txt'>ワンタッチで浄水、原水が切り換えられる浄水機能付き混合水栓。シャワーヘッドが伸びるので、広いシンクのお掃除も簡単です。</p>
              </div>
            </div>

            <div className="box box-2">
              <div className="merit-flex">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/kit_03.jpg" alt="" />
                  </div>
                  <p className="c-cap"></p>
                </div>
                <ul className='merit-box'>
                  <li className='list'><span>自動炊飯機能</span></li>
                  <li className='list'><span>高温炒めモード</span></li>
                  <li className='list'><span>湯わかし機能</span></li>
                  <li className='list'><span>コンロタイマー</span></li>
                  <li className='list'><span>水なし両面<br />焼きグリル</span></li>
                  <li className='list'><span>揚げ物<br />温度調節機能</span></li>
                </ul>
              </div>
              <div className="txt-box">
                <p className='ttl'>ガラストップコンロ</p>
                <p className='txt'>高温自動温度調節機能やコンロ・グリル操作ボタン戻し忘れお知らせ機能などを搭載した安全性の高いコンロです。また、自動炊飯や高温炒めなど様々なモードを搭載し、毎日の料理をサポートします。</p>
              </div>
            </div>

            <div className="box box-3">
              <div className="img-box">
                <div className="img">
                  <StaticImage src="../assets/images/quality/kit_04.jpg" alt="" />
                </div>
                <p className="c-cap"></p>
              </div>
              <div className="txt-box">
                <p className='ttl'>サイドフード</p>
                <p className='txt'>スタイリッシュなデザインのマントル型のレンジフードを採用。整流板付で、吸引力にも優れています。</p>
              </div>
            </div>

            <div className="box box-4">
              <div className="img-box">
                <div className="img">
                  <StaticImage src="../assets/images/quality/kit_05.jpg" alt="" />
                </div>
                <p className="c-cap"></p>
              </div>
              <div className="txt-box">
                <p className='ttl'>無水両面焼きグリル付きガスコンロ</p>
                <p className='txt'>ガスコンロには、無水両面焼きグリルを装備。裏返しする手間がなく、調理時間も短縮されます。</p>
              </div>
            </div>

            <div className="box box-5">
              <div className="img-box">
                <div className="img">
                  <StaticImage src="../assets/images/quality/kit_06.jpg" alt="" />
                </div>
                <p className="c-cap"></p>
              </div>
              <div className="txt-box">
                <p className='ttl'>食器洗い乾燥機</p>
                <p className='txt'>洗浄から乾燥までコースが選べ、家事の手間を軽減します。</p>
              </div>
            </div>

            <div className="box box-6">
              <div className="img-box">
                <div className="img">
                  <StaticImage src="../assets/images/quality/kit_07.jpg" alt="" />
                </div>
                <p className="c-cap"></p>
              </div>
              <div className="txt-box">
                <p className='ttl'>油はね防止ガラス</p>
                <p className='txt'>対面キッチンの開放感を保ちながら、コンロからダイニング側への油はねを防ぎます。</p>
              </div>
            </div>

            <div className="box box-7">
              <div className="img-box">
                <div className="img">
                  <StaticImage src="../assets/images/quality/kit_08.jpg" alt="" />
                </div>
                <p className="c-cap"></p>
              </div>
              <div className="txt-box">
                <p className='ttl'>スライド収納</p>
                <p className='txt'>収納物がひと目で見渡せ、奥の物も取り出しやすい引出タイプのスライド収納を採用しました。</p>
              </div>
            </div>

            <div className="box box-8">
              <div className="img-box">
                <div className="img">
                  <StaticImage src="../assets/images/quality/kit_09.jpg" alt="" />
                </div>
                <p className="c-cap"></p>
              </div>
              <div className="txt-box">
                <p className='ttl'>ブルモーション機能</p>
                <p className='txt'>引き出しは指挟みなどを防ぎ、ゆっくり静かに閉まるブルム社製のブルモーション機能を採用しました。</p>
              </div>
            </div>

            <div className="box box-9">
              <div className="img-box">
                <div className="img">
                  <StaticImage src="../assets/images/quality/kit_10.jpg" alt="" />
                </div>
                <p className="c-cap"></p>
              </div>
              <div className="txt-box">
                <p className='ttl'>ホーローパネル</p>
                <p className='txt'>気になる油汚れも、さっとひと拭きで、お手入れがしやすいキッチンパネルです。（マグネット対応）</p>
              </div>
            </div>

            <div className="box box-10">
              <div className="img-box">
                <div className="img">
                  <StaticImage src="../assets/images/quality/kit_11.jpg" alt="" />
                </div>
                <p className="c-cap"></p>
              </div>
              <div className="txt-box">
                <p className='ttl'>メラミン化粧板面材</p>
                <p className='txt'>汚れが付きにくく、付いた汚れも簡単に落とせるメラミン化粧板をキッチンキャビネットの面材として採用しました。</p>
              </div>
            </div>

            <div className="box box-11">
              <div className="img-box">
                <div className="img">
                  <StaticImage src="../assets/images/quality/kit_12.jpg" alt="" />
                </div>
                <p className="c-cap"></p>
              </div>
              <div className="txt-box">
                <p className='ttl'>包丁ポケット</p>
                <p className='txt'>シンク下キャビネットの扉の内側にあり、包丁が取り出しやすくすっきり収まります。</p>
              </div>
            </div>

            <div className="box box-12">
              <div className="img-box">
                <div className="img">
                  <StaticImage src="../assets/images/quality/kit_13.jpg" alt="" />
                </div>
                <p className="c-cap"></p>
              </div>
              <div className="txt-box">
                <p className='ttl'>人造大理石カウンター</p>
                <p className='txt'>天板は傷がつきにくく耐久性に優れた人造大理石を採用。お手入れを容易に行えます。</p>
              </div>
            </div>

            <div className="box box-13">
              <div className="txt-box txt-box-1">
                <p className='ttl'>ユーティリティシンクE</p>
                <p className='txt'>シンクを3つの層で立体的に使用できる多層設計を採用。「洗う」+「調理する」+「片付ける」をより効率的で快適なスペースを実現しました。また、シンクに水や食器類があたる音を軽減する静音設計のワイドシンクです。</p>
              </div>
              <div className="txt-box txt-box-2">
                <p className='ttl'>作業スペース拡大ワークトップスペース</p>
                <p className='txt'>プレートを設置すると、シンクの上にまな板を置くことができ、広い作業スペースが確保できます。野菜や食器の水切りとして便利です。</p>
              </div>
              <div className="img-box">
                <div className="img">
                  <StaticImage src="../assets/images/quality/kit_14.png" alt="" />
                </div>
                <p className="c-cap"></p>
              </div>
            </div>
          </div>

        </div>
      </section>


      <section className='sec-laundry' id='laundry'>

        <QualityLinkBox
          act='laundry'
        />

        <div className="bath-wrap">
          <div className="com-flex">

            <div className="flex-box-1">
              <div className="img-box main-img box">
                <div className="img">
                  <StaticImage src="../assets/images/quality/bath_01.jpg" alt="" />
                  <p className="c-cap cap">BATHROOM</p>
                </div>
              </div>

              <div className="flex-box-2">
                <div className="box box-1">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/bath_02.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>浴室暖房乾燥機（ガス式）</p>
                    <p className='txt'>洗濯物を乾かす乾燥機能。暖房・涼風機能も備えています。</p>
                  </div>
                </div>

                <div className="box box-3">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/bath_03.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>くるりんポイ排水口</p>
                    <p className='txt'>髪の毛が集まりやすく、捨てやすい形状。ポイと簡単に捨てられ、お掃除も簡単です。</p>
                  </div>
                </div>

                <div className="box box-4">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/bath_04.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>ワンプッシュ排水</p>
                    <p className='txt'>使い勝手に配慮した、指で押すだけで浴槽の排水口の開閉ができる排水栓です。</p>
                  </div>
                </div>

                <div className="box box-5">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/bath_05.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>セミオートバス</p>
                    <p className='txt'>お湯はりから追いだき、保温まで、スイッチひとつの操作で行うセミオートバスです。</p>
                  </div>
                </div>

                <div className="box box-6">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/bath_06.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>モザイクパターン床</p>
                    <p className='txt'>床が乾きやすく、水はけのよい素材とカタチで、水たまりを防ぎます。</p>
                  </div>
                </div>

                <div className="box box-7">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/bath_07.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>とるピカスリムカウンター</p>
                    <p className='txt'>取り回しのしやすい大きさで、小物等を置くのに便利。取り外して洗えます。</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-box-3">

              <div className="flex-box-4">
                <div className="box box-8">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/bath_08.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>FRP製ストレートライン浴槽</p>
                    <p className='txt'>スタイリッシュな形状と、こだわりのソフトな入浴感でゆっくりとリラックスできます。</p>
                  </div>
                </div>

                <div className="box box-9">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/bath_09.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>LED照明</p>
                    <p className='txt'>LEDのやわらかな光はゆったり、くつろぎの浴室を演出します。</p>
                  </div>
                </div>

                <div className="box box-10">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/bath_10.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>浴槽手摺</p>
                    <p className='txt'>入浴時の安全性に配慮し、浴室内に手すりを設置。お子様やご高齢の方も安心です。</p>
                  </div>
                </div>

                <div className="box box-11">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/bath_11.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>エネルック対応リモコン</p>
                    <p className='txt'>省エネの成果が見えるエネルック機能を搭載したリモコンを採用しました。</p>
                  </div>
                </div>

                <div className="box box-12">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/bath_12.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>浴室内タオル掛け</p>
                    <p className='txt'>フック付なので小物などを掛けられ便利です。</p>
                  </div>
                </div>

                <div className="box box-13">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/bath_13.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>ランドリーパイプ2本</p>
                    <p className='txt'>浴室でも洗濯物が干せるように、ランドリーパイプを設置。梅雨など雨の多い季節にうれしい設備です。</p>
                  </div>
                </div>
              </div>

              <div className="flex-box-5">
                <div className="box box-14">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/bath_14.jpg" alt="" />
                    </div>
                    <p className="c-cap">参考写真</p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>キレイドア</p>
                    <p className='txt'>カビが発生しやすい浴室側のゴムパッキンがなくて汚れにくく、お掃除もラクにできます。</p>
                  </div>
                </div>

                <div className="box box-15">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/bath_15.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>エコフルシャワー</p>
                    <p className='txt'>シャワーヘッドに内蔵した羽根車が水圧を上げて、少ない水量でも勢いのあるシャワーを可能にした超節水シャワーです。超節水のエコフルシャワーはバスタイムの省エネに大きく貢献します。</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="pow-wrap">
          <div className="com-flex">

            <div className="flex-box-1">
              <div className="img-box main-img box">
                <div className="img">
                  <StaticImage src="../assets/images/quality/pow_01.jpg" alt="" />
                  <p className="c-cap cap">POWDER ROOM</p>
                </div>
              </div>

              <div className="flex-box-2">
                <div className="box box-1">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/pow_02.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>ボウル一体型<br />人造大理石カウンター</p>
                    <p className='txt'>スタイリッシュなデザインの洗面化粧台。お手入れも容易です。</p>
                  </div>
                </div>

                <div className="box box-1">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/pow_03.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>シングルレバー混合水栓</p>
                    <p className='txt'>ワンレバーで湯量や温度が調節できるシングルレバー混合水栓を採用しています。</p>
                  </div>
                </div>

                <div className="box box-1">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/pow_04.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>三面鏡裏収納</p>
                    <p className='txt'>三面鏡の裏側には収納スペースを設け、化粧品や小物などをすっきりと整理できます。</p>
                  </div>
                </div>

                <div className="box box-1">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/pow_05.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>チャイルドミラー</p>
                    <p className='txt'>お子さまも利用できるよう、三面鏡下部の低い位置にもミラーを設置しています。</p>
                  </div>
                </div>

                <div className="box box-1">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/pow_06.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>フランジレス排水栓</p>
                    <p className='txt'>金具をなくしたフランジレスの排水栓を採用。隙間に汚れが入りにくく、清掃も簡単です。</p>
                  </div>
                </div>

                <div className="box box-1">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/pow_07.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>リネン庫</p>
                    <p className='txt'>シャンプーや洗剤などの買い置き、タオルも出し入れしやすいリネン庫を確保しています。</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-box-3">

              <div className="flex-box-4">
                <div className="box box-8">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/pow_08.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>二段引き出し</p>
                    <p className='txt'>小物を収納できる引き出しをカウンター下に。カウンターまわりをすっきりと整理できます。</p>
                  </div>
                </div>

                <div className="box box-9">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/pow_09.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>ヘルスメーター入れ</p>
                    <p className='txt'>洗面化粧台の足元部分にヘルスメーターの収納スペースを確保しました。</p>
                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>

      </section>


      <section className='sec-amenity'>

        <QualityLinkBox
          act='amenity'
        />

        <div className="amenity-wrap" id='amenity'>

          <div className="com-flex">
            <p className='com-ttl'>暮らしの快適性を高める<br className='_sp' />先進の設備仕様をすみずみに。</p>
            <p className='com-subttl'>AMENITY</p>

            <div className="flex-box-1">
              <div className="left">
                <p className="p1">ローシルエットトイレ</p>
                <p className="p2">すっきりと美しいトイレ空間を実現します。</p>
                <div className="img">
                  <StaticImage src="../assets/images/quality/ameni_01.jpg" alt="" />
                </div>
              </div>
              <div className="right">
                <div className="box">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/ameni_02.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>トイレ手洗いカウンタ−</p>
                    <p className='txt'>トイレを快適にご使用いただける手洗いカウンターを設置。</p>
                  </div>
                </div>
                <div className="box">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/ameni_03.png" alt="" />
                    </div>
                    <p className="c-cap black">参考写真</p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>セフィオンテクト</p>
                    <p className='txt'>汚れがつきにくい独自の防汚技術です。</p>
                  </div>
                </div>
                <div className="box">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/ameni_04.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>トイレ吊戸棚</p>
                    <p className='txt'>トイレットペーパーや⼩物などの収納に便利な吊⼾棚を設置しました。</p>
                  </div>
                </div>
                <div className="box">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/ameni_05.png" alt="" />
                    </div>
                    <p className="c-cap black">概念図</p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>トルネード洗浄</p>
                    <p className='txt'>渦を巻くトルネード水流が、少ない水量を有効に使ってしつこい汚れもきれいに洗浄します。</p>
                  </div>
                </div>

              </div>
            </div>

            <div className="flex-box-2">
              <div className="img-box main-img box">
                <div className="img">
                  <StaticImage src="../assets/images/quality/ameni_06.png" alt="" />
                </div>
                <p className="c-cap cap"></p>
              </div>

              <div className="flex-box-3">
                <div className="box">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/ameni_07.png" alt="" />
                    </div>
                    <p className="c-cap black">概念図</p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>複層ガラス</p>
                    <p className='txt'>2枚のガラスの間に空気層を設けた断熱性の高い仕様。冷暖房効率を高め結露も抑制します。</p>
                  </div>
                </div>
                <div className="box">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/ameni_08.jpg" alt="" />
                    </div>
                    <p className="c-cap">参考写真</p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>床暖房</p>
                    <p className='txt'>室内を足元から心地よく温める床暖房をリビング・ダイニングに設置。エアコンのように、空気を乾燥させず快適です。</p>
                  </div>
                </div>
                <div className="box">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/ameni_09.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>ホーム保安灯</p>
                    <p className='txt'>停電時に自動点灯して足元を照らします。取り外せば懐中電灯としても使用できます。</p>
                  </div>
                </div>
                <div className="box">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/ameni_10.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>ドアキャッチャー</p>
                    <p className='txt'>ドアを押すだけでロックができ、もういちど押すと解除ができ便利です。</p>
                    <p className='c-note'>※リビング・ダイニングのファッションドアのみ。</p>
                  </div>
                </div>
                <div className="box">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/ameni_11.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>カーテンボックス</p>
                    <p className='txt'>カーテンレールを隠し、スマートな住空間を演出するカーテンボックスを採用しました。</p>
                  </div>
                </div>
                <div className="box">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/ameni_12.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>マルチメディアコンセント</p>
                    <p className='txt'>テレビや電話、インターネットなどの端子を一ヶ所に集約しました。</p>
                  </div>
                </div>

              </div>

            </div>

            <div className="flex-box-4">
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/ameni_13.jpg" alt="" />
                  </div>
                  <p className="c-cap"></p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>フラットフロア</p>
                  <p className='txt'>住戸内の浴室出入口や廊下と水廻り間など、段差を抑えた設計です。</p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/ameni_14.jpg" alt="" />
                  </div>
                  <p className="c-cap"></p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>LEDダウンライト</p>
                  <p className='txt'>省エネで長寿命のLEDダウンライトを採用。家計にも環境にも優しい照明です。</p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/ameni_15.jpg" alt="" />
                  </div>
                  <p className="c-cap"></p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>シートフローリング</p>
                  <p className='txt'>ワックスが不要で、キズが付きにくくお手入れが簡単なシートフローリングを採用しています。</p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/ameni_16.jpg" alt="" />
                  </div>
                  <p className="c-cap"></p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>人感センサー</p>
                  <p className='txt'>玄関には、人の動きを感知して自動的に点灯する人感センサーが設置されています。</p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/ameni_17.jpg" alt="" />
                  </div>
                  <p className="c-cap"></p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>リノクロス</p>
                  <p className='txt'>クロスの表面を特殊コーティングをしているのでキズや埃が付きにくいです。</p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/ameni_18.jpg" alt="" />
                  </div>
                  <p className="c-cap"></p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>吐水弁付ランドリー水栓</p>
                  <p className='txt'>万一、ホースが外れた場合には止水弁が作動し、瞬時に給水をストップ。水漏れの被害を防ぎます。</p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/ameni_19.jpg" alt="" />
                  </div>
                  <p className="c-cap black">image photo</p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>4K・8K放送対応</p>
                  <p className='txt'>次世代の高画質な映像を楽しめる「4K・８K放送」に対応しています。</p>
                </div>
              </div>
              <div className="box ex">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/ameni_20.png" alt="" />
                  </div>
                  <p className="c-cap"></p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>スイッチ<br />コンセント<br />高さ</p>
                  <p className='txt'>コンセントやスイッチは無理のない姿勢で使える高さに設置しました。<span className='c-note'>※1</span></p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/ameni_21.jpg" alt="" />
                  </div>
                  <p className="c-cap black">image photo</p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>宅配ボックス<span>（メールボックス一体型）</span></p>
                  <p className='txt'>省スペース性とデザイン性を重視したメールボックス一体型タイプ。在宅・不在を問わず届いた荷物を受け取ることができます。<span className='c-note'>※1Fエントランスに設置。</span></p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/ameni_22.jpg" alt="" />
                  </div>
                  <p className="c-cap black">image photo</p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>ペットと同居可</p>
                  <p className='txt'>愛するペットと一緒に暮らしていただけるペット対応マンションです。</p>
                  <p className='c-note'>※ペットの飼育については管理規約に従っていただきます。</p>
                </div>
              </div>
            </div>

            <p className="c-note note">※1：設置する位置、条件等により変更となる場合があります。</p>

          </div>
        </div>


        <div className="storage-wrap" id=''>

          <div className="com-flex">
            <p className='com-subttl'>STORAGE</p>

            <div className="flex-box-1">
              <div className="box box-1">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/strg_01.jpg" alt="" className='_pc' />
                    <StaticImage src="../assets/images/quality/strg_01_sp.jpg" alt="" className='_sp' />
                  </div>
                  <p className="c-cap left">※一部住戸除く</p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>ウォークインクロゼット</p>
                  <p className='txt'>しまいやすく取り出しやすいウォークインクロゼットを採用。衣類などをたっぷり収納可能なハンガーパイプと上部には棚を設けています。</p>
                </div>
              </div>

              <div className="flex-box-2">
                <div className="box">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/strg_02.jpg" alt="" />
                    </div>
                    <p className="c-cap left">※一部住戸除く</p>
                    <p className="c-cap">参考写真</p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>布団クロゼット</p>
                    <p className='txt'>来客用の布団を取り出しやすく収納もできます。小物や季節モノのアイテムを効率的にしまえる大型収納。</p>
                  </div>
                </div>
                <div className="box">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/strg_03.jpg" alt="" />
                    </div>
                    <p className="c-cap left">※一部住戸除く</p>
                    <p className="c-cap">参考写真</p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>納戸</p>
                    <p className='txt'>季節アイテムやアウトドア用品などをすっきりしまえる大型収納スペースです。</p>
                  </div>
                </div>
                <div className="box">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/strg_04.jpg" alt="" />
                    </div>
                    <p className="c-cap left">※一部住戸除く</p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>トール型シューズボックス</p>
                    <p className='txt'>ご家族の靴がスッキリと収まる収納力のあるトール型シューズボックス。傘立てもビルトインし、日々便利に利用できます。</p>
                  </div>
                </div>
                <div className="box">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/strg_05.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>物入れ</p>
                    <p className='txt'>棚の位置を組み替えられる（一部固定）物入をご用意。お持ちのアイテムに合わせた、無駄のない収納を実現します。</p>
                  </div>
                </div>
                <div className="box">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/strg_06.jpg" alt="" />
                    </div>
                    <p className="c-cap left">※一部住戸除く</p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>パントリー</p>
                    <p className='txt'>缶詰やパスタなどの食品や調味料などのストックに便利なパントリーをキッチンのコンロ脇などに設置。</p>
                  </div>
                </div>
                <div className="box">
                  <div className="img-box">
                    <div className="img">
                      <StaticImage src="../assets/images/quality/strg_07.jpg" alt="" />
                    </div>
                    <p className="c-cap"></p>
                  </div>
                  <div className="txt-box">
                    <p className='ttl'>リネン庫</p>
                    <p className='txt'>シャンプーや洗剤などの買い置き、タオルも出し入れしやすいリネン庫を確保しています。</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>



      <section className='sec-quality'>

        <QualityLinkBox
          act='quality'
        />

        <div className="ee-wrap" id='quality'>

          <div className="com-flex">
            <p className='com-ttl'>これからの暮らしを見据えた<br className='_sp' />次世代型クオリティが充実。</p>
            <p className='com-subttl'>ECOLOGY & ECONOMY</p>

            <div className="flex-box-1">
              <div className="box box-1">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/eco_01.png" alt="" className='_pc' />
                    <StaticImage src="../assets/images/quality/eco_01_sp.png" alt="" className='_sp' />
                  </div>
                  <p className="c-cap"></p>
                </div>
              </div>
              <div className="box box-2">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/eco_02.png" alt="" className='_pc' />
                    <StaticImage src="../assets/images/quality/eco_02_sp.png" alt="" className='_sp' />
                  </div>
                  <p className="c-cap"></p>
                </div>
              </div>
              <div className="box box-3">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/eco_03.png" alt="" className='_pc' />
                    <StaticImage src="../assets/images/quality/eco_03_sp.png" alt="" className='_sp' />
                  </div>
                  <p className="c-cap"></p>
                </div>
              </div>
              <div className="box box-4">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/eco_04.png" alt="" className='_pc' />
                    <StaticImage src="../assets/images/quality/eco_04_sp.png" alt="" className='_sp' />
                  </div>
                  <p className="c-cap"></p>
                </div>
              </div>

              <p className="c-note note">※1：提携のインターネットJ:COM契約の場合10％割引となります。割引適用には事前にお手続きが必要となります。※2：従来便器／当社約10年前商品（C720R）●年間使用日数：365日●家族4人（男性2人・女性2人）●大1回／日・人、小3回／日・人（省エネ・防犯住宅推進アプローチブックより）</p>
            </div>


            <div className="flex-box-2">
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/eco_05.jpg" alt="" />
                  </div>
                  <p className="c-cap black">参考写真</p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>浄水機能付き混合水栓</p>
                  <p className='txt'>従来品と比較して約24％の省エネ効果を生みます。水と湯の境にクリック感を設け、意識的に省エネができるので、水量の出しすぎを抑制。無駄遣いを防止します。</p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/eco_06.png" alt="" />
                  </div>
                  <p className="c-cap"></p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>エコフルシャワー</p>
                  <p className='txt'>シャワーヘッドに内蔵した羽根車が水圧を上げて、少ない水量でも勢いのあるシャワーを可能にした超節水シャワーです。</p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/eco_07.jpg" alt="" />
                  </div>
                  <p className="c-cap"></p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>食器洗い乾燥機</p>
                  <p className='txt'>食器を手で洗った場合の約1／7の水量で洗浄する食器洗い乾燥機。使用する水道代はもちろん、洗剤の量も抑えることができ、経済的で環境に優しいタイプです。</p>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="internet-wrap" id='amenity'>

          <div className="com-flex">
            <p className='com-subttl'>INTERNET</p>

            <div className="flex-box-1">
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/net.jpg" alt="" className='_pc' />
                    <StaticImage src="../assets/images/quality/net_sp.png" alt="" className='_sp' />
                  </div>
                  <p className="c-cap"></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="security-wrap" id='amenity'>

          <div className="com-flex">
            <p className='com-subttl'>SECURITY</p>

            <div className="flex-box-1">
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/sec_01.png" alt="" className='_pc' />
                    <StaticImage src="../assets/images/quality/sec_01_sp.png" alt="" className='_sp' />
                  </div>
                  <p className="c-cap"></p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/sec_02.png" alt="" className='_pc' />
                    <StaticImage src="../assets/images/quality/sec_02_sp.png" alt="" className='_sp' />
                  </div>
                  <p className="c-cap"></p>
                </div>
              </div>
            </div>

            <div className="flex-box-2">
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/sec_03.jpg" alt="" />
                  </div>
                  <p className="c-cap">image photo</p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>防災備品</p>
                  <p className='txt'>共用設備として、火災・地震、その他の非常事態に備え、災害時を想定した防災グッズを備える防災倉庫を１階に設置しています。</p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/sec_04.jpg" alt="" />
                  </div>
                  <p className="c-cap">参考写真</p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>防犯カメラ</p>
                  <p className='txt'>敷地内の要所に防犯カメラを設置。録画した映像は管理室で一定期間保管し、犯罪の抑止効果を発揮します。</p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/sec_05.jpg" alt="" />
                  </div>
                  <p className="c-cap"></p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>ダブルロックドア</p>
                  <p className='txt'>シリンダーを玄関扉の上下2ヶ所に設置し、防犯性を向上させるダブルロックを採用しました。</p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/sec_06.jpg" alt="" />
                  </div>
                  <p className="c-cap"></p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>防犯サムターン</p>
                  <p className='txt'>内側から操作しないと開けられないスイッチ式サムターンを採用しました。</p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/sec_07.jpg" alt="" />
                  </div>
                  <p className="c-cap"></p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>鎌デッド錠</p>
                  <p className='txt'>鎌状にせり出すデッドボルトがドア枠のストライク部とかみ合い、バールなどによるこじ開けに対して強い抵抗力を発揮します。</p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/sec_08.jpg" alt="" />
                  </div>
                  <p className="c-cap"></p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>蓋付ドアスコープ</p>
                  <p className='txt'>ドアスコープから室内の灯が漏れない蓋付タイプを採用しました。</p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/sec_09.jpg" alt="" />
                  </div>
                  <p className="c-cap"></p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>対震ドアガード</p>
                  <p className='txt'>解錠時のドアをロックしたまま半開きにすることができます。</p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/sec_10.jpg" alt="" />
                  </div>
                  <p className="c-cap black left">参考写真</p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>リバーシブルディンプルキー</p>
                  <p className='txt'>玄関ドアは、約5兆5千億通りもの鍵違い数を実現し複製が困難でピッキング対策に有効なシリンダーキーを採用しました。</p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/sec_11.jpg" alt="" />
                  </div>
                  <p className="c-cap black">参考写真</p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>カラーモニター付きインターホン</p>
                  <p className='txt'>エントランスのオートロックと連動。お部屋から画像と音声で来訪者を確認。ハンズフリータイプです。</p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/sec_12.jpg" alt="" />
                  </div>
                  <p className="c-cap"></p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>チャイルドロック</p>
                  <p className='txt'>リビングの窓にはチャイルドロックを採用。小さなお子さまがいるご家庭にも安心です。</p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/sec_13.jpg" alt="" />
                  </div>
                  <p className="c-cap"></p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>防犯センサー<span className='c-note'>※4</span></p>
                  <p className='txt'>警戒設定中に窓や玄関ドアが開くと、インターホンから警報音を発し、警備会社に自動通報されます。</p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/sec_14.jpg" alt="" />
                  </div>
                  <p className="c-cap left">参考写真</p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>ルーバー面格子</p>
                  <p className='txt'>横格子スタイルの面格子に網戸を一体化した防犯性と使い勝手に優れた仕様です。</p>
                  <p className='c-note'>※実際の物とは色等異なります。</p>
                </div>
              </div>

            </div>

            <p className="c-note note">※3：Tebraキーの動作距離は 作動範囲があり、設置環境により変動します。<br />※4：玄関:全住戸、窓:2F住戸（共通廊下に面する 窓は除く）、3~14FのEタイプ（洋室（1）、洋室 （2）のみ）。</p>

          </div>
        </div>

        <div className="service-wrap" id='amenity'>

          <div className="com-flex">
            <p className='com-subttl'>AFTER SERVICE</p>

            <div className="flex-box-1">
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/service.png" alt="" className='_pc' />
                    <StaticImage src="../assets/images/quality/service_sp.png" alt="" className='_sp' />
                  </div>
                  <p className="c-cap"></p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>


      <section className='sec-strct'>

        <QualityLinkBox
          act='strct'
        />

        <div className="strct-wrap" id='strct'>

          <div className="com-flex">
            <p className='com-ttl'>毎日の安心・安全を支える高品位なセキュリティと構造。</p>
            <p className='com-subttl'>STRUCTURE</p>

            <div className="flex-box-1">
              <div className="box w1">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/strctr_01.png" alt="" className='_pc' />
                    <StaticImage src="../assets/images/quality/strctr_01_sp.png" alt="" className='_sp' />
                  </div>
                  <p className="c-cap"></p>
                </div>
              </div>
              <div className="box w2">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/strctr_02.png" alt="" />
                  </div>
                  <p className="c-cap bkack">概念図</p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>二重床・二重天井<span className='c-note'>（一部除く）</span></p>
                  <p className='txt'>床はコンクリートスラブと床材の間に空気層を設けた二重床構造。天井面を二重天井とすることで、リフォームやメンテナンスを行い易くし、さらに遮音性も高めています。</p>
                </div>
              </div>
              <div className="box w2">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/strctr_03.png" alt="" />
                  </div>
                  <p className="c-cap"></p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>外壁・戸境壁<span className='c-note'>（一部除く）</span></p>
                  <p className='txt'>建物外壁のコンクリート厚は、約150㎜以上（廊下・バルコニー面除く）を確保。建物の強度･遮音性を高めるために戸境壁は約220㎜以上で、隣戸の生活音に配慮。</p>
                </div>
              </div>
              <div className="box w2">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/strctr_04.png" alt="" />
                  </div>
                  <p className="c-cap"></p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>戸境壁内蔵梁<span className='c-note'>（一部除く）</span></p>
                  <p className='txt'>従来の梁を、戸境壁に内蔵することにより、スッキリとした室内を実現しました。</p>
                </div>
              </div>
              <div className="box w2">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/strctr_05.png" alt="" />
                  </div>
                  <p className="c-cap">概念図</p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>アウトフレーム工法</p>
                  <p className='txt'>柱の出っ張りをなくし室内をすっきりとすることで家具などの配置もしやすく、スペースを有効に使うことができます。<span className='c-note'>※バルコニー側のみ（一部除く）</span></p>
                </div>
              </div>
              <div className="box w2">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/strctr_06.png" alt="" />
                  </div>
                  <p className="c-cap">概念図</p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>地震管制機能付エレベーター</p>
                  <p className='txt'>万一の地震時に一定以上の揺れを感知すると、走行中のエレベーターは最寄りのフロアに自動停止。ドアが開くことで、乗客の安全を確保します。</p>
                </div>
              </div>
              <div className="box w2">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/strctr_07.png" alt="" />
                  </div>
                  <p className="c-cap">概念図</p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>対震枠玄関ドア</p>
                  <p className='txt'>対震枠は防水性能や耐風性能を満たした上で、ドア本体とドア枠の間に適切なクリアランスを設けています。大地震によってドア枠が変形しても、このクリアランスにより、ドア本体への変形を防ぎ開閉不良を生じにくくしています。</p>
                </div>
              </div>
              <div className="box w3">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/strctr_08.png" alt="" />
                  </div>
                  <p className="c-cap">概念図</p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>ダブル配筋<span className='c-note'>（一部除く）</span></p>
                  <p className='txt'>マンションの構造上重要となる構造壁には鉄筋を二重に組むダブル配筋を採用。シングル配筋より高い強度と耐久性を実現しています。</p>
                </div>
              </div>
              <div className="box w3">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/strctr_09.png" alt="" />
                  </div>
                  <p className="c-cap">概念図</p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>溶接閉鎖型筋<span className='c-note'>（一部除く）</span></p>
                  <p className='txt'>柱の帯筋には、鉄筋を予め溶接してつなぎ合わせることで、地震の横揺れに対し粘り強い柱を実現し、高い耐震性を発揮する溶接閉鎖型筋を採用しました。</p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/strctr_10.png" alt="" />
                  </div>
                  <p className="c-cap">概念図</p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>アンボンド工法</p>
                  <p className='txt'>アンボンド工法とは、天井や床を支えるコンクリートの中にPC鋼線を通し、引っ張る力によって床スラブのたわみを防ぐ工法です。それにより天井に小梁のでっぱりがない、フラットですっきりとした住空間を実現しています。</p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/strctr_11.png" alt="" />
                  </div>
                  <p className="c-cap">概念図</p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>水セメント比50％以下</p>
                  <p className='txt'>水密性が高く、セメントの水和結晶を緻密に生成させた、耐久性の高いコンクリートを採用しています。（杭を除く）</p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/strctr_12.png" alt="" />
                  </div>
                  <p className="c-cap">概念図</p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>防火・防音設置工法</p>
                  <p className='txt'>居室に直接、接する共用排水堅管には、防火・防音設置工法を施し、音漏れを軽減しています。</p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/strctr_13.png" alt="" />
                  </div>
                  <p className="c-cap">概念図</p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>乾式壁（ALCパネル）工法</p>
                  <p className='txt'>バルコニーと共用廊下側の壁は、乾式壁（ALCパネル）工法が採用されています。地震の揺れ等からの影響を避ける非構造体のため、壁のクラック等の発生が低減されています。<span className='c-note'>※雨掛かりの外壁は除く。</span></p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/strctr_14.png" alt="" />
                  </div>
                  <p className="c-cap">概念図</p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>コンクリートのかぶり厚</p>
                  <p className='txt'>鉄筋の錆びを長期間にわたって抑制するために、コンクリートのかぶり厚を建築基準法に基づき確保しました。これにより雨などの水分の浸透を防ぎ、構造体の耐久性を向上させています。</p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/strctr_15.png" alt="" />
                  </div>
                  <p className="c-cap">概念図</p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>コンクリート強度</p>
                  <p className='txt'>住棟には設計基準強度Fc=30N/㎟以上のコンクリートを採用。住棟の柱・梁・主要な壁等には耐久設計基準強度24N／㎟コンクリートを採用しています。</p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/strctr_16.png" alt="" />
                  </div>
                  <p className="c-cap">概念図</p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>雨掛り外壁部の仕様</p>
                  <p className='txt'>鉄筋の弱点は、錆びやすいことです。これを補っているのがコンクリートです。雨掛りに面する外壁にあらかじめ誘発目地を設け、雨水が浸入しないようにシーリングする対策をしています。</p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/strctr_17.png" alt="" />
                  </div>
                  <p className="c-cap">概念図</p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>断熱性を高めた建物構造</p>
                  <p className='txt'>最上階住戸への配慮として、屋上には外部側へ断熱材を敷設。また断熱材は外壁側から天井スラブ下までカバーし、外部の冷気を伝わりにくくしています。</p>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <div className="img">
                    <StaticImage src="../assets/images/quality/strctr_18.png" alt="" />
                  </div>
                  <p className="c-cap"></p>
                </div>
                <div className="txt-box">
                  <p className='ttl'>住宅性能評価書</p>
                  <p className='txt'>ご購入前の確かな安心と、ご入居後の大きな信頼のために、国土交通省指定の第三者機関から住宅の性能について客観的かつ公正な審査を受け、評価書を取得しています。</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <section className='sec-note'>
        <div className="c-content">
          <div className="com-note-wrap">
            <p className="c-note">※掲載の写真はモデルルームAタイプを撮影（2022年3月撮影）したものに⼀部CG処理をしたものです。家具、備品等は配置例を示したもので価格には含まれません。また、オプション、設計変更仕様が含まれています。予めご了承ください。<br />※「image photo」の表記がある写真は全てイメージです。　※掲載の参考写真はメーカーまたは当社他物件の施工例であり、当物件と仕様・形状・色等は異なります。<br />※掲載の概念図はイメージであり実際とは異なります。※数値はあくまで目安であり、記載通りの削減を保証するものではありません。</p>
          </div>
        </div>
      </section>


      {/* CONTENTS */}
    </main>
  )
}

export default QualityPage
